export const VEHICLE_MODELS = [
  "ACCESS",
  "ACCESS125",
  "BURGMAN",
  "BURGMAN-EX",
  "SWISH",
  "AVENIS",
  "INTRUDER",
  "GIXXER",
  "NOT_AVAILABLE",
];

export const VEHICLE_COLORS = [
  "WHITE",
  "GREENISH BLUE",
  "MATT BLACK",
  "MATT GREY",
  "MOON GREY",
  "MATT RED",
  "MATT SILVER",
  "BRONZE",
  "BLUE",
  "DT BEIGE",
  "DT GREEN",
  "NOT_AVAILABLE",
];

export const JS_CHECKEDIN = "CHECKED IN";
export const JS_UNDER_REPAIR = "UNDER REPAIR";
export const JS_REPAIR_COMPLETE = "REPAIR COMPLETE";
export const JS_TRIAL_COMPLETE = "TRIAL COMPLETE";
export const JS_BILLING_COMPLETE = "BILLING COMPLETE";
export const PS_COMPANY = "COMPANY";
export const PS_WORKSHOP = "WORKSHOP";

export const JOB_STATUS = [
  JS_CHECKEDIN,
  JS_UNDER_REPAIR,
  JS_REPAIR_COMPLETE,
  JS_REPAIR_COMPLETE,
  JS_TRIAL_COMPLETE,
  JS_BILLING_COMPLETE,
];

export const SERVICE_TYPE = [
  "1st FREE SERVICE",
  "2nd FREE SERVICE",
  "3rd FREE SERVICE",
  "4th FREE SERVICE",
  "5th FREE SERVICE",
  "6th FREE SERVICE",
  "PAID SERVICE",
  "REPAIR",
  "1st AMC SERVICE",
  "2nd AMC SERVICE",
  "3rd AMC SERVICE",
  "4th AMC SERVICE",
  "5th AMC SERVICE",
  "6th AMC SERVICE",
  "ACCIDENT",
  "7th AMC SERVICE",
  "8th AMC SERVICE",
  "9th AMC SERVICE",
  "10th AMC SERVICE",
  "11th AMC SERVICE",
  "12th AMC SERVICE",
];

export const TECHNICIAN = [
  "JUNAID",
  "JAVED",
  "SHABBIR",
  "IRFAN",
  "NOOR",
  "ASIF",
  "SULTAN",
  "MONIS",
  "SOHAIL",
  "SAHIL"
];

export const CO_RING_NOANSWER = "RING NO ANSWER";
export const CO_BUSY_CALLBACK = "BUSY CALL BACK";
export const CO_WRONG_NUMBER = "WRONG NUMBER";
export const CO_APPT_BOOKED = "APPT BOOKED";
export const CO_APPT_RESCHEDULED = "APPT RESCHEDULED";
export const CO_SERVICE_DONE_STAR = "SERVICE DONE STAR";
export const CO_SERVICE_DONE_OTHERS = "SERVICE DONE OTHERS";
export const CO_APPT_CONFIRMED = "APPT CONFIRMED";
export const CO_NOT_INTERESTED = "NOT INTERESTED";

export const CALL_OUTCOME = [
  CO_RING_NOANSWER,
  CO_BUSY_CALLBACK,
  CO_WRONG_NUMBER,
  CO_APPT_BOOKED,
  CO_APPT_RESCHEDULED,
  CO_SERVICE_DONE_STAR,
  CO_SERVICE_DONE_OTHERS,
  CO_APPT_CONFIRMED,
  CO_NOT_INTERESTED,
];

export const NI_REASON = ["ADDRESS CHANGED", "BIKE SOLD", "PREFER_OTHER"];

export const CT_NEWAPPT = "NEW APPOINTMENT";
export const CT_1STCALLBACK = "CALLBACK-1";
export const CT_2NDCALLBACK = "CALLBACK-2";
export const CT_3RDCALLBACK = "CALLBACK-3";
export const CT_4THCALLBACK = "CALLBACK-4";
export const CT_5THCALLBACK = "CALLBACK-5";
export const CT_6THCALLBACK = "CALLBACK-6";
export const CT_APPT_CONFIRM = "APPT CONFIRMATION";
export const CT_APPT_MISSED = "APPT MISSED";

const CALL_OUT_MAP = [
  [CT_NEWAPPT, CO_RING_NOANSWER, CT_1STCALLBACK],
  [CT_NEWAPPT, CO_BUSY_CALLBACK, CT_1STCALLBACK],
  [CT_NEWAPPT, CO_WRONG_NUMBER, "NA"],
  [CT_NEWAPPT, CO_APPT_BOOKED, CT_APPT_CONFIRM],
  [CT_NEWAPPT, CO_SERVICE_DONE_OTHERS, CT_NEWAPPT],
  [CT_NEWAPPT, CO_SERVICE_DONE_STAR, CT_NEWAPPT],
  [CT_NEWAPPT, CO_NOT_INTERESTED, "NA"],

  [CT_APPT_MISSED, CO_RING_NOANSWER, CT_APPT_MISSED],
  [CT_APPT_MISSED, CO_BUSY_CALLBACK, CT_APPT_MISSED],
  [CT_APPT_MISSED, CO_APPT_RESCHEDULED, CT_APPT_CONFIRM],
  [CT_APPT_MISSED, CO_SERVICE_DONE_OTHERS, CT_NEWAPPT],
  [CT_APPT_MISSED, CO_SERVICE_DONE_STAR, CT_NEWAPPT],
  [CT_APPT_MISSED, CO_NOT_INTERESTED, "NA"],

  [CT_APPT_CONFIRM, CO_RING_NOANSWER, CT_APPT_CONFIRM],
  [CT_APPT_CONFIRM, CO_BUSY_CALLBACK, CT_APPT_CONFIRM],
  [CT_APPT_CONFIRM, CO_APPT_CONFIRMED, "NA"],
  [CT_APPT_CONFIRM, CO_APPT_RESCHEDULED, CT_APPT_CONFIRM],
  [CT_APPT_CONFIRM, CO_SERVICE_DONE_OTHERS, CT_NEWAPPT],
  [CT_APPT_CONFIRM, CO_SERVICE_DONE_STAR, CT_NEWAPPT],
  [CT_APPT_CONFIRM, CO_NOT_INTERESTED, "NA"],

  [CT_1STCALLBACK, CO_RING_NOANSWER, CT_2NDCALLBACK],
  [CT_1STCALLBACK, CO_BUSY_CALLBACK, CT_2NDCALLBACK],
  [CT_1STCALLBACK, CO_WRONG_NUMBER, "NA"],
  [CT_1STCALLBACK, CO_APPT_BOOKED, CT_APPT_CONFIRM],
  [CT_1STCALLBACK, CO_SERVICE_DONE_OTHERS, CT_NEWAPPT],
  [CT_1STCALLBACK, CO_SERVICE_DONE_STAR, CT_NEWAPPT],
  [CT_1STCALLBACK, CO_NOT_INTERESTED, "NA"],

  [CT_2NDCALLBACK, CO_RING_NOANSWER, CT_3RDCALLBACK],
  [CT_2NDCALLBACK, CO_BUSY_CALLBACK, CT_3RDCALLBACK],
  [CT_2NDCALLBACK, CO_WRONG_NUMBER, "NA"],
  [CT_2NDCALLBACK, CO_APPT_BOOKED, CT_APPT_CONFIRM],
  [CT_2NDCALLBACK, CO_SERVICE_DONE_OTHERS, CT_NEWAPPT],
  [CT_2NDCALLBACK, CO_SERVICE_DONE_STAR, CT_NEWAPPT],
  [CT_2NDCALLBACK, CO_NOT_INTERESTED, "NA"],

  [CT_3RDCALLBACK, CO_RING_NOANSWER, CT_4THCALLBACK],
  [CT_3RDCALLBACK, CO_BUSY_CALLBACK, CT_4THCALLBACK],
  [CT_3RDCALLBACK, CO_WRONG_NUMBER, "NA"],
  [CT_3RDCALLBACK, CO_APPT_BOOKED, CT_APPT_CONFIRM],
  [CT_3RDCALLBACK, CO_SERVICE_DONE_OTHERS, CT_NEWAPPT],
  [CT_3RDCALLBACK, CO_SERVICE_DONE_STAR, CT_NEWAPPT],
  [CT_3RDCALLBACK, CO_NOT_INTERESTED, "NA"],

  [CT_4THCALLBACK, CO_RING_NOANSWER, CT_5THCALLBACK],
  [CT_4THCALLBACK, CO_BUSY_CALLBACK, CT_5THCALLBACK],
  [CT_4THCALLBACK, CO_WRONG_NUMBER, "NA"],
  [CT_4THCALLBACK, CO_APPT_BOOKED, CT_APPT_CONFIRM],
  [CT_4THCALLBACK, CO_SERVICE_DONE_OTHERS, CT_NEWAPPT],
  [CT_4THCALLBACK, CO_SERVICE_DONE_STAR, CT_NEWAPPT],
  [CT_4THCALLBACK, CO_NOT_INTERESTED, "NA"],

  [CT_5THCALLBACK, CO_RING_NOANSWER, CT_6THCALLBACK],
  [CT_5THCALLBACK, CO_BUSY_CALLBACK, CT_6THCALLBACK],
  [CT_5THCALLBACK, CO_WRONG_NUMBER, "NA"],
  [CT_5THCALLBACK, CO_APPT_BOOKED, CT_APPT_CONFIRM],
  [CT_5THCALLBACK, CO_SERVICE_DONE_OTHERS, CT_NEWAPPT],
  [CT_5THCALLBACK, CO_SERVICE_DONE_STAR, CT_NEWAPPT],
  [CT_5THCALLBACK, CO_NOT_INTERESTED, "NA"],

  [CT_6THCALLBACK, CO_RING_NOANSWER, "NA"],
  [CT_6THCALLBACK, CO_BUSY_CALLBACK, "NA"],
  [CT_6THCALLBACK, CO_WRONG_NUMBER, "NA"],
  [CT_6THCALLBACK, CO_APPT_BOOKED, CT_APPT_CONFIRM],
  [CT_6THCALLBACK, CO_SERVICE_DONE_OTHERS, CT_NEWAPPT],
  [CT_6THCALLBACK, CO_SERVICE_DONE_STAR, CT_NEWAPPT],
  [CT_6THCALLBACK, CO_NOT_INTERESTED, "NA"],
];

export var CALL_OUTCOME_TYPE = [{}];

CALL_OUT_MAP.map((item, index) => {
  //console.log('index: ', index, ' item: ', item);
  //CALL_OUTCOME_TYPE[index]['calltype'] = item[0];
  //CALL_OUTCOME_TYPE[index]["calloutcome"] = item[1];
  //CALL_OUTCOME_TYPE[index]["nextcall"] = item[2];

  CALL_OUTCOME_TYPE.push({
    callType: item[0],
    callOutcome: item[1],
    nextCallType: item[2],
  });
});

//console.log("CALLOUTMAP ==> ", CALL_OUTCOME_TYPE);

export const ADVISOR = ["MOHSIN"];

export const SERVICE_CATEGORY_MAP = [
  {
    service_type: "1st FREE SERVICE",
    service_category: "FREE SERVICE",
    next_service_type: "2nd FREE SERVICE",
    next_service_category: "FREE SERVICE",
  },
  {
    service_type: "2nd FREE SERVICE",
    service_category: "FREE SERVICE",
    next_service_type: "3rd FREE SERVICE",
    next_service_category: "FREE SERVICE",
  },
  {
    service_type: "3rd FREE SERVICE",
    service_category: "FREE SERVICE",
    next_service_type: "4th FREE SERVICE",
    next_service_category: "FREE SERVICE",
  },
  {
    service_type: "4th FREE SERVICE",
    service_category: "FREE SERVICE",
    next_service_type: "5th FREE SERVICE",
    next_service_category: "FREE SERVICE",
  },
  {
    service_type: "5th FREE SERVICE",
    service_category: "FREE SERVICE",
    next_service_type: "6th FREE SERVICE",
    next_service_category: "PAID SERVICE",
  },
  {
    service_type: "6th FREE SERVICE",
    service_category: "FREE SERVICE",
    next_service_type: "PAID SERVICE",
    next_service_category: "PAID SERVICE",
  },
  {
    service_type: "PAID SERVICE",
    service_category: "PAID SERVICE",
    next_service_type: "PAID SERVICE",
    next_service_category: "PAID SERVICE",
  },
  {
    service_type: "REPAIR",
    service_category: "PAID SERVICE",
    next_service_type: "PAID SERVICE",
    next_service_category: "PAID SERVICE",
  },
  {
    service_type: "1st AMC SERVICE",
    service_category: "AMC",
    next_service_type: "2nd AMC SERVICE",
    next_service_category: "AMC",
  },
  {
    service_type: "2nd AMC SERVICE",
    service_category: "AMC",
    next_service_type: "3rd AMC SERVICE",
    next_service_category: "AMC",
  },
  {
    service_type: "3rd AMC SERVICE",
    service_category: "AMC SERVICE",
    next_service_type: "4th AMC SERVICE",
    next_service_category: "AMC SERVICE",
  },
  {
    service_type: "4th AMC SERVICE",
    service_category: "AMC SERVICE",
    next_service_type: "5th AMC SERVICE",
    next_service_category: "AMC SERVICE",
  },
  {
    service_type: "5th AMC SERVICE",
    service_category: "AMC SERVICE",
    next_service_type: "6th AMC SERVICE",
    next_service_category: "AMC SERVICE",
  },
  {
    service_type: "6th AMC SERVICE",
    service_category: "AMC SERVICE",
    next_service_type: "7th AMC SERVICE",
    next_service_category: "AMC SERVICE",
  },
  {
    service_type: "7th AMC SERVICE",
    service_category: "AMC SERVICE",
    next_service_type: "8th AMC SERVICE",
    next_service_category: "AMC SERVICE",
  },
  {
    service_type: "8th AMC SERVICE",
    service_category: "AMC SERVICE",
    next_service_type: "9th AMC SERVICE",
    next_service_category: "AMC SERVICE",
  },
  {
    service_type: "9th AMC SERVICE",
    service_category: "AMC SERVICE",
    next_service_type: "10th AMC SERVICE",
    next_service_category: "AMC SERVICE",
  },
  {
    service_type: "10th AMC SERVICE",
    service_category: "AMC SERVICE",
    next_service_type: "11th AMC SERVICE",
    next_service_category: "AMC SERVICE",
  },
  {
    service_type: "11th AMC SERVICE",
    service_category: "AMC SERVICE",
    next_service_type: "12th AMC SERVICE",
    next_service_category: "PAID SERVICE",
  },
  {
    service_type: "12th AMC SERVICE",
    service_category: "AMC SERVICE",
    next_service_type: "PAID SERVICE",
    next_service_category: "PAID SERVICE",
  },
  {
    service_type: "ACCIDENT",
    service_category: "PAID SERVICE",
    next_service_type: "PAID SERVICE",
    next_service_category: "PAID SERVICE",
  },
  {
    service_type: "FREE SERVICE",
    service_category: "FREE SERVICE",
    next_service_type: "FREE SERVICE",
    next_service_category: "FREE SERVICE",
  },
];

export const NEXT_JOB_STATUS = [
  {
    status: JS_CHECKEDIN,
    next_status: [JS_CHECKEDIN, JS_UNDER_REPAIR],
  },
  {
    status: JS_UNDER_REPAIR,
    next_status: [JS_UNDER_REPAIR, JS_REPAIR_COMPLETE],
  },
  {
    status: JS_REPAIR_COMPLETE,
    next_status: [JS_REPAIR_COMPLETE, JS_TRIAL_COMPLETE],
  },
  {
    status: JS_TRIAL_COMPLETE,
    next_status: [JS_TRIAL_COMPLETE, JS_BILLING_COMPLETE],
  },
  {
    status: JS_BILLING_COMPLETE,
    next_status: [JS_BILLING_COMPLETE],
  },
];

export const PAYMENT_SOURCE = ["WORKSHOP", "COMPANY"];

export const PAYMENT_MODE = ["CASH", "GPAY", "PAYTM", "OTHER"];

export const EXPENSE_TYPE = [
  {
    type: "MAINTENANCE",
    category: "MISC",
  },
  {
    type: "STAFF FOOD",
    category: "STAFF",
  },
  {
    type: "SPARES PURCHASE",
    category: "PURCHASE",
  },
  {
    type: "STAFF PETROL",
    category: "STAFF",
  },
  {
    type: "BATTERY",
    category: "PURCHASE",
  },
  {
    type: "WASHING",
    category: "PURCHASE",
  },
  {
    type: "WATER RO",
    category: "STAFF",
  },
  {
    type: "INTERNET BILL",
    category: "UTILITY",
  },
  {
    type: "ELECTRIC BILL",
    category: "UTILITY",
  },
  {
    type: "SHOP RENT",
    category: "RENT",
  },
  {
    type: "BOOKING FAISAL",
    category: "PARTNER",
  },
  {
    type: "BOOKING YUSUF",
    category: "PARTNER",
  },
  {
    type: "PETTY CASH",
    category: "MISC",
  },
  {
    type: "VAS VENDOR PAYMENT",
    category: "VAS EXPENSE",
  },
  {
    type: "OFFICE SUPPLIES",
    category: "MISC",
  },
  {
    type: "EMI PAYMENT",
    category: "UTILITY",
  },
  {
    type: "VAS MATERIAL",
    category: "VAS EXPENSE",
  },
  {
    type: "JUNAID SALARY",
    category: "SALARY",
  },
  {
    type: "SHABBIR SALARY",
    category: "SALARY",
  },
  {
    type: "NOOR SALARY",
    category: "SALARY",
  },
  {
    type: "SAMREEN SALARY",
    category: "SALARY",
  },
  {
    type: "MOHSIN SALARY",
    category: "SALARY",
  },
  {
    type: "ASIF SALARY",
    category: "SALARY",
  },
  {
    type: "HAZRAT SALARY",
    category: "SALARY",
  },
  {
    type: "SHARUKH SALARY",
    category: "SALARY",
  },
  {
    type: "SUJIT SALARY",
    category: "SALARY",
  },
  {
    type: "PARVEEN SALARY",
    category: "SALARY",
  },
  {
    type: "ALI BHAI SALARY",
    category: "SALARY",
  },
  {
    type: "SULTAN SALARY",
    category: "SALARY",
  },
  {
    type: "MONIS SALARY",
    category: "SALARY",
  },
  {
    type: "SOHAIL SALARY",
    category: "SALARY",
  },
  {
    type: "SAHIL SALARY",
    category: "SALARY",
  },
];

// Appointments
export const EP_GETAPPOINTMENT_LIST = "/api/v1/getAppointmentList";
export const EP_ADD_CUSTOMER_CALL = "/api/v1/addCustomerCall";
export const EP_ADD_NEW_APPOINTMENT = "/api/v1/addNewAppointment";
export const EP_RESCHEDULE_APPOINTMENT = "/api/v1/rescheduleAppointment";
export const EP_GET_CALLBACKS = "/api/v1/getCallBacks";
export const EP_GET_MISSED_APPT_CALLS = "/api/v1/getMissedApptCalls";
export const EP_GET_APPOINTMENT_DETAILS = "/api/v1/getAppointmentDetails";
export const EP_SET_APPOINTMENT_JOBID = "/api/v1/setAppointmentJobId";
export const EP_GET_APPOINTMENT_LIST = "/api/v1/getAppointmentList";
export const EP_GET_STAR_APPT_CALLS = "/api/v1/getStarApptCalls";
export const EP_CANCEL_APPT = "/api/v1/cancelAppointment";
export const EP_SET_APPOINTMENT_VISIT = "/api/v1/setAppointmentVisit";
export const EP_GET_JOB_APPT = "/api/v1/getJobAppointment";

// Account services
export const EP_FETCH_DAILY_COLLECTION = "/api/v1/getDailyCollection";
export const EP_ADD_DAILY_COLLECTION = "/api/v1/addDailyCollection";
export const EP_GET_DAILY_CASHFLOW = "/api/v1/getDailyCashFlow";
export const EP_ADD_DAILY_CASHFLOW = "/api/v1/addDailyCashFlow";
export const EP_UPDATE_BALANCES = "/api/v1/updateBalances";
export const EP_FETCH_BALANCES = "/api/v1/fetchBalances";

// Customer services
export const EP_FETCH_CUSTOMERBY_MOBILE = "/api/v1/findCustomer";
export const EP_FETCH_CUSTOMER_VEHICLES = "/api/v1/getVehicles";
export const EP_GET_CUSTOMERBY_ID = "/api/v1/getCustomerbyId";
export const EP_FETCH_VEHICLE_ASSIGNMENT = "/api/v1/checkVehicleAssigned";
export const EP_GET_VEHICLE_DETAILS = "/api/v1/getVehicleDetails";
export const EP_UPDATE_VEHICLE = "/api/v1/updateVehicle";
export const EP_ASSIGN_VEHICLE = "/api/v1/assignVehicle";
export const EP_ADD_CUSTOMER = "/api/v1/addCustomer";
export const EP_UPDATE_CUSTOMER = "/api/v1/updateCustomer";
export const EP_SET_CUSTOMER_INACTIVE = "/api/v1/setCustomerInactive";
export const EP_SET_CUSTOMER_LAST_VISIT = "/api/v1/setCustomerLastVisit";
export const EP_SET_CUSTOMER_LAST_CALL = "/api/v1/setCustomerLastCall";
export const EP_CUSTOMER_CALL_HISTORY = "/api/v1/getCustomerCallHistory";
export const EP_CUSTOMER_VISIT_HISTORY = "/api/v1/getCustomerVisitHistory";
export const EP_CUSTOMER_CALL_HISTORY_VEHICLE =
  "/api/v1/getCustomerCallHistoryByVehicle";
export const EP_CUSTOMER_VISIT_HISTORY_VEHICLE =
  "/api/v1/getCustomerVisitHistoryByVehicle";
export const EP_CUSTOMER_CALL_HISTORY_VEHICLE_MOBILE =
  "/api/v1/getCustomerCallHistoryByVehicleMobile";
export const EP_CUSTOMER_VISIT_HISTORY_VEHICLE_MOBILE =
  "/api/v1/getCustomerVisitHistoryByVehicleMobile";

// Job services
export const EP_CREATE_JOB = "/api/v1/createJob";
export const EP_UPDATE_JOB = "api/v1/updateJob";
export const EP_CREATE_JOB_ITEMS = "/api/v1/createJobItems";
export const EP_DELETE_JOB_ITEMS = "/api/v1/deleteJobItems";
export const EP_GET_JOB_ID = "/api/v1/nextJobId";
export const EP_GET_JOB_LIST = "/api/v1/fetchJobs";
export const EP_GET_JOB_DETAILS = "/api/v1/getJobDetails";
export const EP_SET_JOB_STATUS = "/api/v1/setJobStatus";
export const EP_ADD_JOB_STAGE = "/api/v1/addJobStage";
export const EP_UPDATE_JOB_STATE_END = "/api/v1/updateJobStageEnd";
export const EP_UPDATE_JOB_PAYMENT = "/api/v1/updateJobPayment";
export const EP_GET_JOBLIST_BYDATE = "/api/v1/getJobListByDate";
export const EP_GET_JOBLIST_DETAILS_BYDATE = "/api/v1/getJobListDetailsByDate";

// Part services
export const EP_FETCH_PARTS = "/api/v1/partList";

// Purchase services
export const EP_GET_PURCHASE_LIST = "/api/v1/fetchPurchases";
export const EP_FETCH_VENDORS = "/api/v1/fetchVendors";
export const EP_GET_ALL_PURCHASE_ITEMS_LIST = "/api/v1/fetchAllPurchaseItems";
export const EP_CREATE_PURCHASE = "/api/v1/createPurchase";
export const EP_UPDATE_PURCHASE = "/api/v1/updatePurchase";
export const EP_CREATE_PURCHASE_ITEMS = "/api/v1/createPurchaseItems";
export const EP_DELETE_PURCHASE_ITEMS = "/api/v1/deletePurchaseItems";
export const EP_FETCH_PAYMENTS = "/api/v1/fetchPayments";
export const EP_ADD_PURCHASE_PAYMENT = "/api/v1/addPurchasePayment";
export const EP_CREATE_EXPENSE = "/api/v1/createExpense";
export const EP_FETCH_PURCHASE_PAYMENTS = "/api/v1/fetchPurchasePayments";
export const EP_FETCH_EXPENSES_BYDATE = "/api/v1/fetchExpenseByDate";
export const EP_FETCH_EXPENSES = "/api/v1/fetchExpenses";
export const EP_FETCH_EXPENSES_COMPANY = "/api/v1/fetchExpensesCompany";
export const EP_FETCH_EXPENSES_WORKSHOP = "/api/v1/fetchExpensesWorkshop";

// stock service
export const EP_ADD_STOCK = "/api/v1/addStock";
export const EP_REMOVE_STOCK = "/api/v1/removeStock";
export const EP_REDUCE_STOCK = "/api/v1/reduceStock";

//reports
export const EP_FETCH_MONTHLY_REPORT = "/api/v1/getMonthlyReport";